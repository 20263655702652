.article-img {
  img {
    width: 100%;
  }
}
.article-date {
  font-size: rem-calc(14);
}

.btn-article-back {
  border: rem-calc(2) solid $black!important;
}

.pagination {
  display: flex;
  justify-content: center;
  li {
    display: inline-block;
    a {
      border: rem-calc(2) solid $black;
      color: $black;
      display: block;
      font-size: rem-calc(12);
      margin: rem-calc(4);
      padding: rem-calc(2) rem-calc(4); 
      text-align: center;
      text-decoration: none;     
      &.pagination-btn {
        margin: rem-calc(2) rem-calc(8);
        width: rem-calc(32);
      }
      &.pagination-num {
        margin: rem-calc(2);
      }
    }
    &.selected {
      a {
        background-color: $black;
        color: $white;
      }
    }
  }
}