// Breadcrumbs

.breadcrumbs {
  position: relative;
  z-index: 1;
  .breadcrumb-link, .breadcrumb-current {
    display: inline-block;
    font-weight: 300;
    position: relative;
    @include breakpoint(small){
      font-size: rem-calc(14);
      padding: 0 rem-calc(3) 0 rem-calc(12);
    }
    @include breakpoint(large){
      font-size: rem-calc(16);
      padding: 0 rem-calc(6) 0 rem-calc(18);
    }
    &:before {
      @include vertical-center;
      color: $black;
      content: '>';
      display: block;      
      font-weight: 700;
      left: 0;
      @include breakpoint(small){
        font-size: rem-calc(14);
      }
      @include breakpoint(large){
        font-size: rem-calc(18);
      }
    }
  }
  .breadcrumb-link{
    &:hover {
      text-decoration: underline;
    }
  }

  // separator colour
  &.separator-black {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        color: $black;
      }
    }
  }
  &.separator-dark-grey {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        color: $dark-grey;
      }
    }
  }
  &.separator-light-grey {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        color: $light-grey;
      }
    }
  }
  &.separator-white {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        color: $white;
      }
    }
  }

  // breadcrumb font colour
  &.ele-fc-black {
    .breadcrumb-link, .breadcrumb-current {
      color: $black;
    }
  }
  &.ele-fc-dark-grey {
    .breadcrumb-link, .breadcrumb-current {
      color: $dark-grey;
    }
  }
  &.ele-fc-light-grey {
    .breadcrumb-link, .breadcrumb-current {
      color: $light-grey;
    }
  }
  &.ele-fc-white {
    .breadcrumb-link, .breadcrumb-current {
      color: $white;
    }
  }

  .breadcrumb-current {
    font-weight: 700;
  }
  &.hide-first {
    .breadcrumb-link {
      &:first-of-type {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}

.brd-arrow {
  .breadcrumbs {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        content: '>';        
      }
    }
  }
}
.brd-line {
  .breadcrumbs {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        content: '|';        
      }
    }
  }
}
.brd-slash {
  .breadcrumbs {
    .breadcrumb-link, .breadcrumb-current {
      &:before {
        content: '/';        
      }
    }
  }
}