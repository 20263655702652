// Metropolis
@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Light.eot');
    src: url('/assets/fonts/Metropolis-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Metropolis-Light.otf'),
        url('/assets/fonts/Metropolis-Light.svg#Metropolis-Light') format('svg'),
        url('/assets/fonts/Metropolis-Light.ttf') format('truetype'),
        url('/assets/fonts/Metropolis-Light.woff') format('woff'),
        url('/assets/fonts/Metropolis-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-Regular.eot');
  src: url('/assets/fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Metropolis-Regular.otf'),
      url('/assets/fonts/Metropolis-Regular.svg#Metropolis-Regular') format('svg'),
      url('/assets/fonts/Metropolis-Regular.ttf') format('truetype'),
      url('/assets/fonts/Metropolis-Regular.woff') format('woff'),
      url('/assets/fonts/Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}  

@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-Bold.eot');
  src: url('/assets/fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Metropolis-Bold.otf'),
      url('/assets/fonts/Metropolis-Bold.svg#Metropolis-Bold') format('svg'),
      url('/assets/fonts/Metropolis-Bold.ttf') format('truetype'),
      url('/assets/fonts/Metropolis-Bold.woff') format('woff'),
      url('/assets/fonts/Metropolis-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

// Open sans
@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans-Light.eot');
  src: url('/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/OpenSans-Light.otf'),
      url('/assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg'),
      url('/assets/fonts/OpenSans-Light.ttf') format('truetype'),
      url('/assets/fonts/OpenSans-Light.woff') format('woff'),
      url('/assets/fonts/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans-Regular.eot');
  src: url('/assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/OpenSans-Regular.otf'),
      url('/assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'),
      url('/assets/fonts/OpenSans-Regular.ttf') format('truetype'),
      url('/assets/fonts/OpenSans-Regular.woff') format('woff'),
      url('/assets/fonts/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans-Bold.eot');
  src: url('/assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/OpenSans-Bold.otf'),
      url('/assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg'),
      url('/assets/fonts/OpenSans-Bold.ttf') format('truetype'),
      url('/assets/fonts/OpenSans-Bold.woff') format('woff'),
      url('/assets/fonts/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

// Roboto
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Light.eot');
  src: url('/assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Roboto-Light.otf'),
      url('/assets/fonts/Roboto-Light.svg#Roboto-Light') format('svg'),
      url('/assets/fonts/Roboto-Light.ttf') format('truetype'),
      url('/assets/fonts/Roboto-Light.woff') format('woff'),
      url('/assets/fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.eot');
  src: url('/assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Roboto-Regular.otf'),
      url('/assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg'),
      url('/assets/fonts/Roboto-Regular.ttf') format('truetype'),
      url('/assets/fonts/Roboto-Regular.woff') format('woff'),
      url('/assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Bold.eot');
  src: url('/assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Roboto-Bold.otf'),
      url('/assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg'),
      url('/assets/fonts/Roboto-Bold.ttf') format('truetype'),
      url('/assets/fonts/Roboto-Bold.woff') format('woff'),
      url('/assets/fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

.font-arial {
  font-family: Arial, sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: Arial, sans-serif;
  }
}
.font-metropolis {
  font-family: 'Metropolis', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Metropolis', sans-serif;
  }
}
.font-open-sans {
  font-family: 'OpenSans', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'OpenSans', sans-serif;
  }
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }
}
.font-tahoma {
  font-family: Tahoma, sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: Tahoma, sans-serif;
  }
}
.font-trebuchet-ms {
  font-family: 'Trebuchet MS', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Trebuchet MS', sans-serif;
  }
}
.font-verdana {
  font-family: Verdana, sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: Verdana, sans-serif;
  }
}
  