// Accordions / FAQ
.accordions {
  .acc-item {
    .role-top {
      margin-bottom: rem-calc(24);
      p {
        font-weight: bold;
        margin-bottom: 0;        
      }
    }
    .role-closed-message {
      @extend .section-title-small;
      font-weight: bold;
      margin-bottom: 0; 
      margin-top: rem-calc(32);
    }
    .acc-link {
      background-color: $primary-color;
      color: $white;
      cursor: pointer;
      display: block;
      position: relative;
      width: 100%;
      @include breakpoint(small) {
        font-size: rem-calc(20);
      }
      @include breakpoint(medium) {
        font-size: rem-calc(20);
      }
      .acc-expand {
        background-color: transparent;
        border: rem-calc(2) solid $white;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint(small) {
          height: rem-calc(40);
          width: rem-calc(40);
        }
        @include breakpoint(medium) {
          height: rem-calc(48);
          width: rem-calc(48);
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          background-color: $white;
        }
        &:before {
          bottom: rem-calc(12);
          left: 50%;
          top: rem-calc(12);
          transform: translateX(-50%);
          width: rem-calc(2);
        }
        &:after {
          top: 50%;
          left: rem-calc(12);
          right: rem-calc(12);
          height: rem-calc(2);
          transform: translateY(-50%);
        }
      }
  
      &.open {
        .acc-expand {
          &:after {
            transform: rotate(45deg);
          }
          &:before {
            transform: rotate(45deg);
          }
        }
      }
      &:focus {
        box-shadow: none !important;
        .acc-expand {
          border-color: $black;
          &:before,
          &:after {
            background-color: $black;
          }
        }
      }
    }
  }
  
  .acc-content {
    background-color: $lightest-grey;
    display: none;
    padding-bottom: rem-calc(10);
    @include breakpoint(small) {
      padding: rem-calc(24);
    }
    @include breakpoint(large) {
      padding: rem-calc(32) rem-calc(48);
    }
    a {
      text-decoration: underline;
    }
    .btn {
      margin-right: rem-calc(16);
      text-decoration: none;
    }
  }
  .acc-link.open + .acc-content {
    display: block;
    position: relative;
  }
  &.acc-square {
    .acc-item {
      .acc-expand {
        border-radius: 0;
      }
    }
  }
  &.acc-round {
    border-radius: 50%;
  }
  &.acc-simple {
    .acc-link {
      .acc-expand {
        border: none;
      }
      &.open {
        .acc-expand {          
          &:after {
            display: none;
          }
          &:before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  &.acc-arrow {
    .acc-link {
      .acc-expand {
        border: none;
        &:before {
          -webkit-mask-image: url('/assets/images/icon-acc-down.svg');
          mask-image: url('/assets/images/icon-acc-down.svg');
          -webkit-mask-size: rem-calc(32);
          mask-size: rem-calc(32);
          height: rem-calc(32);
          width: rem-calc(32);
          @include breakpoint(small) {
            top: rem-calc(6);
          }
          @include breakpoint(medium) {
            top: rem-calc(8); 
          }
        }
        &::after {
          display: none;
        }
      }
      &.open {
        .acc-expand {  
          &:before {
            transform: rotate(180deg) translateX(50%);
          }
        }
      }
    }
  }
  &.acc-left {
    .acc-link {
      @include breakpoint(small) {
        padding: rem-calc(20) rem-calc(30) rem-calc(20) rem-calc(72);
      }
      @include breakpoint(medium) {
        padding: rem-calc(24) rem-calc(24) rem-calc(24) rem-calc(84);
      }
    }
    .acc-expand {
      left: rem-calc(16);
    }
  }
  &.acc-right {
    .acc-link {
      @include breakpoint(small) {
        padding: rem-calc(20) rem-calc(72) rem-calc(20) rem-calc(30);
      }
      @include breakpoint(medium) {
        padding: rem-calc(24) rem-calc(84) rem-calc(24) rem-calc(24);
      }
    }
    .acc-expand {
      right: rem-calc(16);
    }
  }
  &.ele-margin {
    .acc-item {
      margin-bottom: rem-calc(10);
    }
    &.ele-border-top-bottom {
      .acc-item {
        .acc-link {
          border-top: rem-calc(1) solid $black;
          border-bottom: rem-calc(1) solid $black;
        }
      }
    }
  }
  &.ele-border-full {
    .acc-item {
      .acc-link {
        border: rem-calc(1) solid $black;
      }
    }
  }
  &.ele-border-top-bottom {
    .acc-item {
      &:first-of-type{
        .acc-link {
          border-top: rem-calc(1) solid $black;
        }       
      }
      .acc-link {
        border-bottom: rem-calc(1) solid $black;    
      } 
    }
    .acc-link {
      border-top: none;
      border-bottom: rem-calc(1) solid $black;    
    }
  }

  &.ele-shadow {
    .acc-item {
      box-shadow: 0 rem-calc(6) rem-calc(16) rgba(0, 0, 0, 0.2);
    }
  }

  // acc font size
  &.acc-fs-large {
    .acc-item {
      .acc-link {
        @include breakpoint(small) {
          font-size: rem-calc(20);
          line-height: rem-calc(20);
        }
        @include breakpoint(medium) {
          font-size: rem-calc(24);
          line-height: rem-calc(24);
        }
      }
    }
  }
  &.acc-fs-medium {
    .acc-item {
      .acc-link {
        @include breakpoint(small) {
          font-size: rem-calc(18);
          line-height: rem-calc(18);
        }
        @include breakpoint(medium) {
          font-size: rem-calc(20);
          line-height: rem-calc(20);
        }
      }      
    }
  }
  &.acc-fs-small {
    .acc-item {
      .acc-link {
        @include breakpoint(small) {
          font-size: rem-calc(16);
          line-height: rem-calc(16);
        }
        @include breakpoint(medium) {
          font-size: rem-calc(18);
          line-height: rem-calc(18);
        }
      }      
    }
  }
  &.acc-fs-x-small {
    .acc-item {
      .acc-link {
        @include breakpoint(small) {
          font-size: rem-calc(16);
          line-height: rem-calc(16);
        }
      }      
    }
  }
  &.acc-fs-xx-small {
    .acc-item {
      .acc-link {
        @include breakpoint(small) {
          font-size: rem-calc(14);
          line-height: rem-calc(14);
        }
      }      
    }
  }

  // bg color 
  &.ele-bg-white {
    .acc-link {
      background-color: $white;
    }    
  }
  &.ele-bg-black {
    .acc-link {
      background-color: $black;
    } 
  }
  &.ele-bg-dark-grey {
    .acc-link {
      background-color: $charcol;
    } 
  }
  &.ele-bg-light-grey {
    .acc-link {
      background-color: $medium-grey;
    } 
  }

  // fc colour
  &.ele-fc-white {
    .acc-link {
      color: $white;
    }
  }
  &.ele-fc-black {
    .acc-link {
      color: $black;
    }
  }
  &.ele-fc-dark-grey {
    .acc-link {
      color: $charcol;
    }
  }
  &.ele-fc-light-grey {
    .acc-link {
      color: $medium-grey;
    }
  }

  // toggle colour
  &.toggle-fc-white {
    .acc-link {
      .acc-expand {
        border-color: $white;
        &:before, &:after {
          background-color: $white;
        }
      }
    }
  }
  &.toggle-fc-black {
    .acc-link {
      .acc-expand {
        border-color: $black;
        &:before, &:after {
          background-color: $black;
        }
      }
    }
  }
  &.toggle-fc-dark-grey {
    .acc-link {
      .acc-expand {
        border-color: $charcol;
        &:before, &:after {
          background-color: $charcol;
        }
      }
    }
  }
  &.toggle-fc-light-grey {
    .acc-link {
      .acc-expand {
        border-color: $medium-grey;
        &:before, &:after {
          background-color: $medium-grey;
        }
      }
    }
  }
}