.glide {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  backface-visibility: hidden;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  touch-action: pan-Y;
  transform-style: preserve-3d;
  white-space: nowrap;
  width: 100%;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  flex-shrink: 0;  
  height: 100%;  
  user-select: none;
  white-space: normal;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .slide-img {
    img {
      width: 100%;
    }
  }

  .slide-card {
    &.ele-border {
      border-radius: rem-calc(16);   
    }
    &.ele-shadow {
      box-shadow: 0 rem-calc(2) rem-calc(10) rgba(0, 0, 0, 0.2);
    }
    &.slide-card-padding-small {
      padding: rem-calc(16);
    }
    &.slide-card-padding-medium {
      padding: rem-calc(24);
    }
    &.slide-card-padding-large {
      padding: rem-calc(32);
    }
    &.slide-card-padding-x-large {
      padding: rem-calc(42);
    }
  }
}

.slide-fix-buttons {
  .glide__slide {
    .slide-card {
      padding-bottom: rem-calc(84);
      position: relative;
      .btn {
        position: absolute;
      }
      &.slide-card-padding-small {
        .btn {
          bottom: rem-calc(16);
        }
      }
      &.slide-card-padding-medium {
        .btn {
          bottom: rem-calc(24);
        }
      }
      &.slide-card-padding-large {
        .btn {
          bottom: rem-calc(32);
        }
      }
      &.slide-card-padding-x-large {
        .btn {
          bottom: rem-calc(42);
        }
      }
    }
  }

}

.text-center {
  .slide-fix-buttons {
    .slide-card {
      .btn {
        margin: 0 auto;
      }
      &.slide-card-padding-small {
        .btn {
          left: rem-calc(16);
          right: rem-calc(16);
        }
      }
      &.slide-card-padding-medium {
        .btn {
          left: rem-calc(24);
          right: rem-calc(24);
        }
      }
      &.slide-card-padding-large {
        .btn {
          left: rem-calc(32);
          right: rem-calc(32);
        }
      }
      &.slide-card-padding-x-large {
        .btn {
          left: rem-calc(42);
          right: rem-calc(42);
        }
      } 
    }
  }    
}
.text-left {
  .slide-fix-buttons {
    .slide-card {
      &.slide-card-padding-small {
        .btn {
          left: rem-calc(16);
        }
      }
      &.slide-card-padding-medium {
        .btn {
          left: rem-calc(24);
        }
      }
      &.slide-card-padding-large {
        .btn {
          left: rem-calc(32);
        }
      }
      &.slide-card-padding-x-large {
        .btn {
          left: rem-calc(42);
        }
      } 
    }
  }     
}
.text-right {
  .slide-fix-buttons {
    .slide-card {
      &.slide-card-padding-small {
        .btn {
          right: rem-calc(16);
        }
      }
      &.slide-card-padding-medium {
        .btn {
          right: rem-calc(24);
        }
      }
      &.slide-card-padding-large {
        .btn {
          right: rem-calc(32);
        }
      }
      &.slide-card-padding-x-large {
        .btn {
          right: rem-calc(42);
        }
      }
    }

  }    
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
  .glide__arrow {
    border-radius: rem-calc(4);
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1); 
    cursor: pointer;
    display: block;
    line-height: 1;
    opacity: 0.5;
    padding: 0;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 150ms ease, border 300ms ease-in-out;
    z-index: 2;    
    &:hover, &:focus {
      opacity: 1;
    }
    &:focus {
      span {
        background-color: $black!important;
      }
    }
  }
}
.glide__arrow--left, .glide__arrow--right {
  @include breakpoint(small) {
    height: rem-calc(32);
    width: rem-calc(32);
  }
  @include breakpoint(medium) {
    height: rem-calc(48);
    width: rem-calc(48);
  }
  span {
    background-color: $white;
    display: block;
    height: 100%;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;  
    mask-repeat: no-repeat;  
    width: 100%;
    @include breakpoint(small) {
      -webkit-mask-size: rem-calc(16);
      mask-size: rem-calc(16);
    }
    @include breakpoint(medium) {
      -webkit-mask-size: rem-calc(32);
      mask-size: rem-calc(32);
    }
  }

}
.glide__arrow--left {
  @include breakpoint(small) {
    left: 0;
  }
  @include breakpoint(medium) {
    left: rem-calc(16);
  }
  span { 
    -webkit-mask-image: url('/assets/images/icon-glide-left.svg');
    mask-image: url('/assets/images/icon-glide-left.svg');
  }
}
.glide__arrow--right {
  @include breakpoint(small) {
    right: 0;
  }
  @include breakpoint(medium) {
    right: rem-calc(16);
  }
  span {
    -webkit-mask-image: url('/assets/images/icon-glide-right.svg');
    mask-image: url('/assets/images/icon-glide-right.svg');
  }
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__arrow:focus {
  outline: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__bullets {
  display: inline-flex;
  left: 50%;  
  list-style: none;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  @include breakpoint(small) {
    bottom: rem-calc(8);
  }
  @include breakpoint(medium) {
    bottom: rem-calc(16);
  }
}
.glide__bullet {
  border: rem-calc(2) solid transparent;
  border-radius: 50%;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 0;
  opacity: 0.75;
  padding: 0;
  transition: all 0.25s ease-in-out;  
  @include breakpoint(small) {
    height: rem-calc(10);
    margin: 0 rem-calc(4);
    width: rem-calc(10);
  }
  @include breakpoint(medium) {
    height: rem-calc(16);
    margin: 0 rem-calc(8);
    width: rem-calc(16);
  }
  &:hover, &:focus {    
    opacity: 1;
  }
  &:focus {
    border-color: $black!important;
    box-shadow: 0 0 $focus-color, 0 2px $black!important;
  }
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet--active {
  opacity: 1;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.slide-padding {
  .glide {
    .glide__slides {
      align-items: stretch;
      padding: rem-calc(16) 0;
      .glide__slide {
        align-self: stretch;
        height: auto;
        .slide-card {
          height: 100%;
        }
      }
    }
  }
  .glide__arrow--left {
    left: 0;
  }
  .glide__arrow--right {
    right: 0;
  }
  .glide__bullets {
    bottom: rem-calc(-20);
  }
}

.glide-fade {
  .glide__slides {
    transform: translate3d(0, 0, 0) !important;
  }

  .glide__slide {
    animation-duration: 0;
    position: absolute;
    top: 0;
    left: 0;
    order: 2;
    opacity: 0;
    transition: opacity 1250ms ease-in-out;
  }

  .glide__slide.glide__slide--active {
    position: relative;
    order: 1;
    opacity: 1;
    z-index: 1;
  }
}

.glide-active-opacity {
  .glide__slide {
    opacity: 0.5;
    transition: opacity 0.25s ease-in;
  }
  .glide__slide.glide__slide--active {
    opacity: 1;
  }
}