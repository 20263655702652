/* Header */
.header{
	position: relative;		
	width: 100%;
	z-index: 10;

	&.header-fixed {
		position: fixed;
	}

	&.header-shadow {
		box-shadow: 0 0 rem-calc(16) 0 rgba(0, 0, 0, 0.4);
	}
	
	&.hd-bg-black {
		background-color: $black;
		.nav-menu-container {
			background-color: $black;
			@include breakpoint(large){
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										background-color: $black;
									}								
								}
							}
						}
					}
				}
			}
		}
	}
	&.hd-bg-dark-grey {
		background-color: $dark-grey;
		.nav-menu-container {
			background-color: $dark-grey;
			@include breakpoint(large){
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										background-color: $dark-grey;
									}								
								}
							}
						}
					}
				}				
			}
		}
	}
	&.hd-bg-light-grey {
		background-color: $light-grey;
		.nav-menu-container {
			background-color: $light-grey;
			@include breakpoint(large){
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										background-color: $light-grey;
									}								
								}
							}
						}
					}
				}				
			}
		}
	}
	&.hd-bg-white {
		background-color: $white;
		.nav-menu-container {
			background-color: $white;
			@include breakpoint(large){
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										background-color: $white;
									}								
								}
							}
						}
					}
				}				
			}
		}
	}

	// logo
	.logo-container {
		display: flex;
		position: relative;
		z-index: 0;
		.logo{
			display: block;	
			font-size: 0;
			padding: rem-calc(10);
			position: relative;
			z-index: 9;
			@include breakpoint(small){
				max-width: rem-calc(160);
			}
			@include breakpoint(medium){
				max-width: rem-calc(192);
			}		
			svg {
				.logo-main {
					fill: $primary-color;
				}
			}	
			&:focus {
				svg{
					.svg-fill {
						fill: $black;
					}
				}
			}
		}
	}

	// nav container
	.nav-menu-container {
		@include breakpoint(small){
			background-color: $white;
			display: none;
			min-height:100vh;
			right:0;
			position: fixed;
			top:0;
			transition: height 0.25s ease;			
			width: calc(100vw - 1rem);
			z-index: 10;			
		}
		@include breakpoint(large){
			background-color: transparent;
			display: block;
			height: auto;
			min-height: auto;
			right: initial;
			position: static;
			top: initial;			
			width: auto;	
		}
		&.is-active{
			display: block;
			@include breakpoint(small){
				overflow: hidden;
				padding: rem-calc(80) 0 rem-calc(32);			
			}
			@include breakpoint(large){
				overflow: initial;
				padding: 0;
			}			
		}				
		.nav {
			ul {
				display: flex;
				justify-content: flex-end;
				list-style: none;
				@include breakpoint(small){					
					flex-direction: column;
					margin: 0;
					text-align: left;
				}
				@include breakpoint(large){
					align-items: center;
					flex-direction: row;
					margin: 0;
					text-align: center;
				}
				li {
					display: inline-block;
					@include breakpoint(small){
						margin: rem-calc(8) 0;
						padding: 0 rem-calc(16);
					}
					@include breakpoint(large){
						margin:0;
					}
					a, .nav-link {	
						color: $black;
						display: inline-block;								
						font-weight: 400;	
						letter-spacing: 0;						
						position: relative;
						text-decoration: none;
						transition: all 0.25s ease;
						@include breakpoint(small){
							font-size: rem-calc(16);
							line-height: rem-calc(22);
						}
						@include breakpoint(large){													
							font-size: rem-calc(18);
							line-height: rem-calc(24);
							margin: 0 rem-calc(16);							
						}
						&:hover, &.active {
							@include breakpoint(large){
								text-decoration: none;
							}								
						}
					}
				}
				&.nav-footer {					
					margin-top: rem-calc(40);
					li {
						margin: rem-calc(2) rem-calc(16);
						a {
							//font-family: 'rig-sans';
							font-weight: 400;
							text-transform: none;
						}
					}
				}
			}
			.is-dropdown-submenu-parent {
				position: relative;
				.nav-link {
					@include breakpoint(large){
						padding-right: rem-calc(32);
						position: relative;
						&:after {
							@include vertical-center;
							background-color: $black;
							content:'';
							height: rem-calc(8);						
							-webkit-mask-image: url('/assets/images/downward_chevron.svg');
							mask-image: url('/assets/images/downward_chevron.svg');
							-webkit-mask-repeat: no-repeat;
							mask-repeat: no-repeat;
							-webkit-mask-size: contain;
							mask-size: contain;						
							right: rem-calc(8);
							width: rem-calc(10);
						}
					}
				}
				.menu {
					display: none;
				}
				.submenu {
					@include breakpoint(small){
						background-color: $submenu-color;
						display: block;
						padding: rem-calc(8) 0;
					}
					@include breakpoint(large){
						background-color: $submenu-color;
						box-shadow: 0 0 rem-calc(16) 0 rgba(0, 0, 0, 0.4);
						display: none;
						left: 50%;
						min-width: rem-calc(280);
						padding: rem-calc(10);
						position: absolute;
						top: calc(100% + 0.75rem);
						transform: translateX(-50%);						
						width: 100%;
						&:before {
							border-color: transparent transparent $submenu-color transparent;
							border-style: solid;
							border-width: 0 rem-calc(8) rem-calc(12) rem-calc(8);
							content: '';
							display: block;
							height: 0;
							left: 50%;
							position: absolute;
							top: rem-calc(-10);
							transform: translateX(-50%);
							width: 0;
						}
					}
					li {
						display: block;
						@include breakpoint(small){
							margin:0;
						}
						@include breakpoint(large){
							margin:0 auto;
							max-width: rem-calc(1240);
							position: relative;
							text-align: center;
							z-index: 2;
						}
						a {		
							cursor: pointer;					
							display: inline-block;							
							opacity: 1;
							@include breakpoint(small only){
								background-color: transparent!important;
								color: $black!important;
								font-size: rem-calc(12);
								line-height: rem-calc(16);
							}
							@include breakpoint(medium only){
								background-color: transparent!important;
								color: $black!important;
								font-size: rem-calc(12);
								line-height: rem-calc(16);
							}
							@include breakpoint(large){									
								background-color: $black;
								border: none;
								border-radius: 0;	
								color: $white;				
								font-size: rem-calc(16);
								line-height: rem-calc(24);
								margin: rem-calc(4) 0;
								text-decoration: none;
								width:100%;
							}
							&:before {
								display: none;
							}
							&.active, &:hover {								
								outline: none;
								text-decoration: underline;
								@include breakpoint(large){
									text-decoration: none;
								}												
							}
						}
					}

					&.js-dropdown-active {
						display: block;
					}	
				}
			}
		}
	}

	// link uppercase
	&.link-upper {
		text-transform: uppercase;
		&.nav-link-btn {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									text-transform: none;
								}
							}
						}
					}
				}
			}
		}
	}
	// link bold
	&.link-bold {		
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	// nav-link-btn
	&.nav-link-btn {
		.nav-menu-container {
			.nav {
				> ul {
					> li {
						&:last-of-type {
							a {
								@extend .btn;
								&:before {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		&.btn-arrow {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									.arrow {
										@include vertical-center();
										background-color: $black;
										background-position: center;
										background-repeat: no-repeat;
										background-size: cover;
										-webkit-mask-repeat: no-repeat;
										mask-repeat: no-repeat;	
										-webkit-mask-size: cover;
										mask-size: cover;				
										height: rem-calc(32);		
										text-align: left;
										transition: all 0.25s ease;
										right: rem-calc(20);
										width: rem-calc(32);
									}
								}
							}
						}
					}
				}
			}
		}
		&.arrow-round {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									padding-right: rem-calc(64);
									.arrow {
										-webkit-mask-image: url('/assets/images/icon-arrow-round.svg');
										mask-image: url('/assets/images/icon-arrow-round.svg');
										height: rem-calc(26);
										width: rem-calc(26);
									}
								}
							}
						}
					}
				}
			}
		}
		&.arrow-round-solid {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									padding-right: rem-calc(64);
									.arrow {
										-webkit-mask-image: url('/assets/images/icon-arrow-round-solid.svg');
										mask-image: url('/assets/images/icon-arrow-round-solid.svg');
										height: rem-calc(26);
										width: rem-calc(26);
									}
								}
							}
						}
					}
				}
			}
		}
		&.arrow-short {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									padding-right: rem-calc(52);
									.arrow {
										-webkit-mask-image: url('/assets/images/icon-arrow-short.svg');
										mask-image: url('/assets/images/icon-arrow-short.svg');
										right: rem-calc(12);
									}
								}
							}
						}
					}
				}
			}

		}
		&.arrow-simple {			
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									padding-right: rem-calc(64);
									.arrow {
										-webkit-mask-image: url('/assets/images/icon-arrow-simple.svg');
										mask-image: url('/assets/images/icon-arrow-simple.svg');
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bold {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}
		&.nav-link-btn-upper {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}

		// nav link btn bg colors
		&.nav-link-btn-bg-black {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									background-color: $black;
									border-color: $black;
									&:hover {
										background-color: $white;
										border-color: $black;
										color: $black;
										.arrow {
											background-color: $black;
										}
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-dark-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									background-color: $dark-grey;
									border-color: $dark-grey;
									&:hover {
										background-color: $white;
										border-color: $dark-grey;
										color: $dark-grey;
										.arrow {
											background-color: $dark-grey;
										}
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-light-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									background-color: $light-grey;
									border-color: $light-grey;
									&:hover {
										background-color: $white;
										border-color: $black;
										color: $black;
										.arrow {
											background-color: $light-grey;
										}
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-white {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									background-color: $white;
									border-color: $white;
									&:hover {
										background-color: $black;
										border-color: $white;
										color: $white;
										.arrow {
											background-color: $white;
										}
									}
								}
							}
						}
					}
				}
			}	
		}

		// nav link btn bg hover colors
		&.nav-link-btn-bg-hv-black {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										background-color: $black;
										border-color: $black;
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-hv-dark-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										background-color: $dark-grey;
										border-color: $dark-grey;
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-hv-light-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										background-color: $light-grey;
										border-color: $light-grey;
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-bg-hv-white {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										background-color: $white;
										border-color: $white;
									}
								}
							}
						}
					}
				}
			}	
		}		

		// button font colors
		&.nav-link-btn-fc-black {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									color: $black;
									.arrow {
										background-color: $black;
									}
								}
							}
						}
					}
				}
			}		
		}
		&.nav-link-btn-fc-dark-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									color: $dark-grey;
									.arrow {
										background-color: $dark-grey;
									}
								}
							}
						}
					}
				}
			}
		}
		&.nav-link-btn-fc-light-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									color: $light-grey;
									.arrow {
										background-color: $light-grey;
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-fc-white {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									color: $white;
									.arrow {
										background-color: $white;
									}
								}
							}
						}
					}
				}
			}	
		}

		// button font hover colors
		&.nav-link-btn-fc-hv-black {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										color: $black;
										.arrow {
											background-color: $black;
										}
									}
								}
							}
						}
					}
				}
			}		
		}
		&.nav-link-btn-fc-hv-dark-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										color: $dark-grey;
										.arrow {
											background-color: $dark-grey;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.nav-link-btn-fc-hv-light-grey {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										color: $light-grey;
										.arrow {
											background-color: $light-grey;
										}
									}
								}
							}
						}
					}
				}
			}	
		}
		&.nav-link-btn-fc-hv-white {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									&:hover {
										color: $white;
										.arrow {
											background-color: $white;
										}
									}
								}
							}
						}
					}
				}
			}	
		}		

		// nav link btn position
		&.nav-btn-center, &.nav-btn-left, &.nav-btn-right {
			.header-content {				
				@include breakpoint(large){	
					position: relative;
				}
			}	
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									@include breakpoint(large){	
										@include vertical-center;
										max-width: rem-calc(224);	
									}		
								}
							}
						}
					}
				}
			}	
		}
		&.nav-btn-left {
			.logo-container {
				@include breakpoint(large){	
					margin-left: rem-calc(224);		
				}	
			}
			.nav-menu-container {	
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									@include breakpoint(large){	
										left: 0;
									}	
								}
							}							
						}
					}
				}
			}	
		}
		&.nav-btn-right {
			.nav-menu-container {
				@include breakpoint(large){	
					padding-right: rem-calc(224);				
				}		
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									@include breakpoint(large){	
										right: 0;
									}		
								}
							}
						}
					}
				}
			}	
		}
	}

	// nav position
	&.nav-p-left {
		.nav-menu-container {
			.nav {
				ul {
					justify-content: flex-start;
				}
			}
		}
	}
	&.nav-p-right {
		.nav-menu-container {
			.nav {
				ul {
					justify-content: flex-end;
				}
			}
		}
	}
	&.nav-p-center {
		.nav-menu-container {
			.nav {
				ul {
					justify-content: center;
				}
			}
		}
	}

	// link font color
	&.link-fc-black {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							color: $black;
						}
					}
				}
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								&:hover, &.active {
									color: $black;
								}
							}
						}
					}
					.nav-link {
						&:after {
							background-color: $black;
						}
					}
				}
			}
		}
	}
	&.link-fc-dark-grey {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							color: $dark-grey;
						}
					}
				}
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								&:hover, &.active {
									color: $dark-grey;
								}
							}
						}
					}
					.nav-link {
						&:after {
							background-color: $dark-grey;
						}
					}
				}
			}
		}
	}
	&.link-fc-light-grey {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							color: $light-grey;
						}
					}
				}
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								&:hover, &.active {
									color: $light-grey;
								}
							}
						}
					}
					.nav-link {
						&:after {
							background-color: $light-grey;
						}
					}
				}
			}
		}
	}
	&.link-fc-white {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							color: $white;
						}
					}
				}
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								&:hover, &.active {
									color: $white;
								}
							}
						}
					}
					.nav-link {
						&:after {
							background-color: $white;
						}
					}
				}
			}
		}
	}
	// Link hover
	&.link-fc-hv-black {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							&:hover, &.active{
								color: $black;
							}							
						}
					}
				}
				.is-dropdown-submenu-parent {
					&.is-active {
						a {
							color: $black;
						}
						.nav-link {
							&:after {
								background-color: $black;
							}
						}
					}
					.nav-link {
						&:hover {
							&:after {
								background-color: $black;
							}
						}
					}
				}
			}
		}
	}
	&.link-fc-hv-dark-grey {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							&:hover, &.active{
								color: $dark-grey;
							}
						}
					}
				}
				.is-dropdown-submenu-parent {
					&.is-active {
						a {
							color: $dark-grey;
						}
						.nav-link {
							&:after {
								background-color: $dark-grey;
							}
						}
					}
					.nav-link {
						&:hover {
							&:after {
								background-color: $dark-grey;
							}
						}
					}
				}
			}
		}
	}
	&.link-fc-hv-light-grey {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							&:hover, &.active{
								color: $light-grey;
							}
						}
					}
				}
				.is-dropdown-submenu-parent {
					&.is-active {
						a {
							color: $light-grey;
						}
						.nav-link {
							&:after {
								background-color: $light-grey;
							}
						}
					}
					.nav-link {
						&:hover {
							&:after {
								background-color: $light-grey;
							}
						}
					}
				}
			}
		}
	}
	&.link-fc-hv-white {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							&:hover, &.active{
								color: $white;
							}
						}
					}
				}
				.is-dropdown-submenu-parent {
					&.is-active {
						a {
							color: $white;
						}
						.nav-link {
							&:after {
								background-color: $white;
							}
						}
					}
					.nav-link {
						&:hover {
							&:after {
								background-color: $white;
							}
						}
					}
				}
			}
		}
	}

	// Submenu Link
	&.submenu-fc-black {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								@include breakpoint(large){			
									color: $black;		
								}										
							}
						}
					}
				}
			}
		}
	}
	&.submenu-fc-dark-grey {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								@include breakpoint(large){			
									color: $dark-grey;		
								}										
							}
						}
					}
				}
			}
		}
	}
	&.submenu-fc-light-grey {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								@include breakpoint(large){			
									color: $light-grey;		
								}										
							}
						}
					}
				}
			}
		}
	}
	&.submenu-fc-white {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						li {
							a {
								@include breakpoint(large){			
									color: $white;		
								}										
							}
						}
					}
				}
			}
		}
	}

	// submenu border
	&.link-bg-hv-black {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						border-top: rem-calc(2) solid $black;
					}
				}
			}
		}
	}
	&.link-bg-hv-dark-grey {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						border-top: rem-calc(2) solid $dark-grey;
					}
				}
			}
		}
	}
	&.link-bg-hv-light-grey {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						border-top: rem-calc(2) solid $light-grey;
					}
				}
			}
		}
	}
	&.link-bg-hv-white {
		.nav-menu-container {
			.nav {
				.is-dropdown-submenu-parent {
					.submenu {
						border-top: rem-calc(2) solid $white;
					}
				}
			}
		}
	}

	// nav button - mobile / tablet
	.nav-menu-button{
		@include vertical-center;
		display: flex;
		justify-content: center;
		right: rem-calc(16);
		z-index: 11;
		@include breakpoint(large){			
			display: none;			
		}		
		.hamburger-menu{
			display: inline-block;
			.hamburger {
			  padding: rem-calc(18) rem-calc(8) rem-calc(8) rem-calc(8);
			  display: inline-block;
			  cursor: pointer;
			  transition-property: opacity, filter;
			  transition-duration: 0.15s;
			  transition-timing-function: linear;
			  font: inherit;
			  color: inherit;
			  text-transform: none;
			  background-color: transparent;
			  border: 0;
			  margin: 0;
			  overflow: visible;
			  outline: none!important;
			  .menu-text {
				  display: none;
				  padding-right: rem-calc(10);
			  }
			  .hamburger-box {
				width: rem-calc(40);
				height: rem-calc(26);
				display: inline-block;
				position: relative;
				.hamburger-inner {
				  display: block;
				  top: 50%;
				  margin-top: rem-calc(-2);
				  width: rem-calc(40);
				  height: rem-calc(4);
				  background-color: $black;
				  border-radius: rem-calc(4);
				  position: absolute;
				  transition-property: transform;
				  transition-duration: 0.15s;
				  transition-timing-function: ease;
				  &:before, &:after{
					content: "";
					display: block;
					width: rem-calc(40);
					height: rem-calc(4);
					background-color: $black;
					border-radius: rem-calc(4);
					position: absolute;
					transition-property: transform;
					transition-duration: 0.15s;
					transition-timing-function: ease;
				  }
				  &:before{
					top: rem-calc(-10);
				  }
				  &:after{
					bottom: rem-calc(-10);
				  }
				}		
			  }
			  &.hamburger--elastic{
				.hamburger-inner{
				  top: rem-calc(2);
				  transition-duration: 0.275s;
				  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  &:before{
					top: rem-calc(10);
					transition: opacity 0.125s 0.275s ease;
				  }
				  &:after{
					top: rem-calc(20);
					transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  }
				}
				&.is-active{
					.menu-text {
						color: $black;
					}
				  .hamburger-inner{
					  background-color: $black;
						transform: translate3d(0, 0.5rem, 0) rotate(135deg);
						transition-delay: 0.075s;
						&:before{
							background-color: $black;
							transition-delay: 0s;
							opacity: 0;
						}
						&:after{
							background-color: $black;
							transform: translate3d(0, -1.25rem, 0) rotate(-270deg);
							transition-delay: 0.075s;
						}
				  }
				}
				&:focus {
					.menu-text {
						color: $black;
					}
					.hamburger-inner {
						background-color: $black!important;
						&:before, &:after {
							background-color: $black!important;
						}
					} 
				  }				
			  }
			}			
		  }			
	}
	&.nav-menu-black {
		.nav-menu-button {
			.hamburger-menu {
				.hamburger {
					.hamburger-box {
						.hamburger-inner {
							background-color: $black;
							&:before, &:after {
								background-color: $black;
							}
						}
					}
				}
			}
		}
	}	
	&.nav-menu-dark-grey {
		.nav-menu-button {
			.hamburger-menu {
				.hamburger {
					.hamburger-box {
						.hamburger-inner {
							background-color: $dark-grey;
							&:before, &:after {
								background-color: $dark-grey;
							}
						}
					}
				}
			}
		}
	}	
	&.nav-menu-light-grey {
		.nav-menu-button {
			.hamburger-menu {
				.hamburger {
					.hamburger-box {
						.hamburger-inner {
							background-color: $light-grey;
							&:before, &:after {
								background-color: $light-grey;
							}
						}
					}
				}
			}
		}
	}	
	&.nav-menu-white {
		.nav-menu-button {
			.hamburger-menu {
				.hamburger {
					.hamburger-box {
						.hamburger-inner {
							background-color: $white;
							&:before, &:after {
								background-color: $white;
							}
						}
					}
				}
			}
		}
	}	

	// nav types
	&.nav-standard {
		.nav-menu-container {
			.nav {
				> ul {
					> li {
						a {
							padding: rem-calc(8) 0;
							&:before {
								bottom: 0;
								background-color: $black;
								content: '';
								display: block;
								height: rem-calc(5);
								left: 50%;
								position: absolute;
								transform: translateX(-50%);
								transition: all 0.25s ease;
								width: 0;
							}
							&:hover, &.active {
								&:before {
									width: 100%;
								}
							}	
							&.active {
								&:before {
									left: 0;
									transform: none;
									width: 100%;
								}
							}								
						}
						&.is-dropdown-submenu-parent {
							&.is-active {
								a {
									&:before {
										display: block;
										width: 100%;
									}
								}
							}
							.submenu {
								li {
									a {
										&:before {
											display: none;
										}
									}
								}
							}
						}

					}
				}
			}
		}
		
		&.link-bg-hv-black {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:before {
									background-color: $black;
								}							
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								&:before {
									background-color: $black;
								}	
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-dark-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:before {
									background-color: $dark-grey;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								&:before {
									background-color: $dark-grey;
								}	
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-light-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:before {
									background-color: $light-grey;
								}									
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								&:before {
									background-color: $light-grey;
								}	
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-white {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:before {
									background-color: $white;
								}									
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								&:before {
									background-color: $white;
								}	
							}
						}
					}
				}
			}
		}		
	}
	&.nav-blocks {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							padding: rem-calc(8) rem-calc(16);							
						}
					}
				}
			}
		}
		&.link-bg-hv-black {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $black;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $black;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-dark-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $dark-grey;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $dark-grey;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-light-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $light-grey;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $light-grey;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-white {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $white;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $white;
							}
						}
					}
				}
			}
		}
		// active font arrow colour 	
		&.link-fc-hv-black {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $black;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-dark-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $dark-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-light-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $light-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-white {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $white;
								}
							}
						}
					}
				}
			}
		}			
	}
	&.nav-border {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							border: rem-calc(2) solid;
							border-radius: rem-calc(24); 
							padding: rem-calc(8) rem-calc(20);							
						}
					}
				}
			}
		}
		&.link-bg-hv-black {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								border-color: $black;
								&:hover, &.active {
									background-color: $black;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $black;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-dark-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								border-color: $dark-grey;
								&:hover, &.active {
									background-color: $dark-grey;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $dark-grey;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-light-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								border-color: $light-grey;
								&:hover, &.active {
									background-color: $light-grey;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $light-grey;
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-white {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								border-color: $white;
								&:hover, &.active {
									background-color: $white;
								}								
							}
						}
					}
					.is-dropdown-submenu-parent {
						&.is-active {
							a {
								background-color: $white;
							}
						}
					}
				}
			}
		}	
		// active font arrow colour 	
		&.link-fc-hv-black {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $black;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-dark-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $dark-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-light-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $light-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-white {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.nav-link {
							&.active {
								&:after {
									background-color: $white;
								}
							}
						}
					}
				}
			}
		}				
	}
	&.nav-hamburger {
		.header-content {
			position: static!important;
		}
		.nav-menu-container {
			background-color: $white;
			display: none;
			min-height:100vh;
			right:0;
			position: fixed;
			top:0;
			transition: height 0.25s ease;			
			width: calc(100vw - 1rem);
			z-index: 10;	
			&.is-active {
				display: block;
				overflow: hidden;
				padding: rem-calc(80) 0 rem-calc(32);	
			}
			.nav {
				ul {
					align-items: unset;
					flex-direction: column;
					justify-content: center;
					margin: 0;
					text-align: left;
					li {
						margin: rem-calc(8) 0;
						padding: 0 rem-calc(16);
						a {
							color: $black;
							font-size: rem-calc(16);
							line-height: rem-calc(22);
							margin: 0!important;
							padding: rem-calc(8)!important;
							&:hover {
								text-decoration: underline;
							}							
						}
					}
				}
				.is-dropdown-submenu-parent {
					a {
						&:after {
							display: none;
						}
					}
				}
				.submenu {
					box-shadow: none;
					background-color: $submenu-color!important;
					display: block;
					left: auto;
					min-width: initial;
					padding: rem-calc(8) 0;					
					position: relative;					
					top: auto;
					transform: none;					
					width: auto;
					&:before {
						display: none;
					}
					li {
						max-width: none;
						text-align: left;
						a {
							background-color: transparent!important;
							color: $black!important;
							font-size: rem-calc(12);
							line-height: rem-calc(16);
							&:hover, &.active {
								text-decoration: underline;
							}							
						}
					}
				}
			}
		}
		.nav-menu-button {
			display: flex!important;
		}
		&.link-fc-black {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									color: $black;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-dark-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									color: $dark-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-light-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									color: $light-grey;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-white {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-black {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										color: $black;
									}									
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-dark-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										color: $dark-grey;
									}									
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-light-grey {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										color: $light-grey;
									}									
								}
							}
						}
					}
				}
			}
		}
		&.link-fc-hv-white {
			.nav-menu-container {
				.nav {
					.is-dropdown-submenu-parent {
						.submenu {
							li {
								a {
									&:hover, &.active {
										color: $white;
									}
									
								}
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-black {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $black;
								}								
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-dark-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $dark-grey;
								}								
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-light-grey {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $light-grey;
								}								
							}
						}
					}
				}
			}
		}
		&.link-bg-hv-white {
			.nav-menu-container {
				.nav {
					ul {
						li {
							a {
								&:hover, &.active {
									background-color: $white;
								}								
							}
						}
					}
				}
			}
		}	
		&.nav-link-btn {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									left: auto;
									position: relative;
									top: auto;
									transform: none;
									right: auto;									
								}
							}
						}
					}
				}
			}
		}		
	}

	// nav font size
	&.nav-f-size-sm {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								font-size: rem-calc(14);					
							}														
						}
					}
				}
			}
		}
	}
	&.nav-f-size-md {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								font-size: rem-calc(16);						
							}														
						}
					}
				}
			}
		}
	}
	&.nav-f-size-lg {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								font-size: rem-calc(18);					
							}														
						}
					}
				}
			}
		}
	}
	&.nav-f-size-xl {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {							
							@include breakpoint(large){
								font-size: rem-calc(20);						
							}							
						}
					}
				}
			}
		}
	}

	// nav font spacing
	&.nav-f-space-sm {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								margin: 0 rem-calc(10);							
							}						
						}
					}
				}
			}
		}
	}
	&.nav-f-space-md {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								margin: 0 rem-calc(16);							
							}							
						}
					}
				}
			}
		}
	}
	&.nav-f-space-lg {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								margin: 0 rem-calc(24);							
							}							
						}
					}
				}
			}
		}
	}
	&.nav-f-space-xl {
		.nav-menu-container {
			.nav {
				ul {
					li {
						a {
							@include breakpoint(large){
								margin: 0 rem-calc(32);							
							}								
						}
					}
				}
			}
		}
	}

	// header social links
	.header-social {
		.social-links {
			display: flex;
			flex-wrap: wrap;  
			justify-content: center;  
			list-style: none;
			margin: 0 auto;    
			li {
				.social-icon {
					align-items: center;
					background-color: $black;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					display: flex;
					justify-content: center;
					margin: rem-calc(6);
					-webkit-mask-position: center;
					mask-position: center;
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-mask-size: contain;
					mask-size: contain;
					@include breakpoint(small) {
						height: rem-calc(32);
						width: rem-calc(32);            
					}
					@include breakpoint(large) {
						height: rem-calc(48);
						width: rem-calc(48);            
					}
					&:hover {
						opacity: 0.9;
					}
					&.facebook {
						background-color: $social-facebook;
						-webkit-mask-image: url('/assets/images/social-facebook.svg');
						mask-image: url('/assets/images/social-facebook.svg');
						&:focus {
							background-image: url('/assets/images/social-facebook.svg');
						}
					}
					&.instagram {
						background-color: $social-instagram;
						-webkit-mask-image: url('/assets/images/social-instagram.svg');
						mask-image: url('/assets/images/social-instagram.svg');
						&:focus {
							background-image: url('/assets/images/social-instagram.svg');
						}
					}
					&.linkedin {
						background-color: $social-linkedin;
						-webkit-mask-image: url('/assets/images/social-linkedin.svg');
						mask-image: url('/assets/images/social-linkedin.svg');
						&:focus {
							background-image: url('/assets/images/social-linkedin.svg');
						}
					}
					&.tiktok {
						background-color: $social-tiktok;
						-webkit-mask-image: url('/assets/images/social-tiktok.svg');
						mask-image: url('/assets/images/social-tiktok.svg');
						&:focus {
							background-image: url('/assets/images/social-tiktok.svg');
						}
					}
					&.twitter {
						background-color: $social-twitter;
						-webkit-mask-image: url('/assets/images/social-twitter.svg');
						mask-image: url('/assets/images/social-twitter.svg');
						&:focus {
							background-image: url('/assets/images/social-twitter.svg');
						}
					}
					&.vimeo {
						background-color: $social-vimeo;
						-webkit-mask-image: url('/assets/images/social-vimeo.svg');
						mask-image: url('/assets/images/social-vimeo.svg');
						&:focus {
							background-image: url('/assets/images/social-vimeo.svg');
						}
					}
					&.youtube {
						background-color: $social-youtube;
						-webkit-mask-image: url('/assets/images/social-youtube.svg');
						mask-image: url('/assets/images/social-youtube.svg');
						&:focus {
							background-image: url('/assets/images/social-youtube.svg');
						}
					}
					&:focus {
						-webkit-mask-image: none;
						mask-image: none;
					}
				}
				&:first-of-type {
					.social-icon {
						margin-left: 0;
					}
				}
				&:last-of-type {
					.social-icon {
						margin-right: 0;
					}
				}
			}
		}
    &.social-size-large {
      .social-links {
        li {
          .social-icon {
            @include breakpoint(small) {
              height: rem-calc(40);
              width: rem-calc(40);            
            }
            @include breakpoint(large) {
              height: rem-calc(64);
              width: rem-calc(64);            
            }
          }
        }
      }
    }
    &.social-size-medium {
      .social-links {
        li {
          .social-icon {
            @include breakpoint(small) {
              height: rem-calc(32);
              width: rem-calc(32);            
            }
            @include breakpoint(large) {
              height: rem-calc(48);
              width: rem-calc(48);            
            }
          }
        }
      }
    }
    &.social-size-small {
      .social-links {
        li {
          .social-icon {
            @include breakpoint(small) {
              height: rem-calc(24);
              width: rem-calc(24);            
            }
            @include breakpoint(large) {
              height: rem-calc(32);
              width: rem-calc(32);            
            }
          }
        }
      }
    }
    &.social-size-tiny {
      .social-links {
        li {
          .social-icon {
            height: rem-calc(20);
            width: rem-calc(20);
          }
        }
      }
    }
    // social icon colour
    &.social-col-black {
      .social-links {
        li {
          .social-icon {
            background-color: $black;
          }
        }
      }
    }
    &.social-col-dark-grey {
      .social-links {
        li {
          .social-icon {
            background-color: $dark-grey;
          }
        }
      }
    }
    &.social-col-light-grey {
      .social-links {
        li {
          .social-icon {
            background-color: $light-grey;
          }
        }
      }
    }
    &.social-col-white {
      .social-links {
        li {
          .social-icon {
            background-color: $white;
          }
        }
      }
    }
	}

	.header-left, .header-center, .header-right {
		display: flex;
    flex-wrap: wrap;
    flex-direction: column;
		&.h-col-left {
			.logo-container {
				justify-content: flex-start;
			}
			.header-social {
				.social-links {
					justify-content: flex-start;
				}
			}
			.header-html {
				text-align: left;
			}
			.nav-menu-container {
				.nav {
					ul {
						justify-content: flex-start;
					}
				}
			}			

		}
		&.h-col-center {
			.logo-container {
				justify-content: center;
			}
			.header-social {
				.social-links {
					justify-content: center;
				}
			}
			.header-html {
				text-align: center;
			}
			.nav-menu-container {
				.nav {
					ul {
						justify-content: center;
					}
				}
			}	

		}
		&.h-col-right {
			.logo-container {
				justify-content: flex-end;
			}
			.header-social {
				.social-links {
					justify-content: flex-end;
				}
			}
			.header-html {
				text-align: right;
			}
			.nav-menu-container {
				.nav {
					ul {
						justify-content: flex-end;
					}
				}
			}	

		}
	}

	.header-left, .header-right {
		&.h-col-left, &.h-col-center, &.h-col-right {
			.header-social {
				.social-links {
					@include breakpoint(small only){
						justify-content: center;
						left: 50%;
						margin: 0 auto;
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
					}
					@include breakpoint(medium only){
						justify-content: center;
						left: 50%;
						margin: 0 auto;
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
					}

				}
			}
		}
		&.h-col-left, &.h-col-right {
			.social-icons-fixed {
				@include breakpoint (large) {
					bottom: 0;
					position: absolute;

				}
			}
		}
		&.h-col-left {
			.social-icons-fixed {
				@include breakpoint (large) {
					left: 0;

				}
			}
		}
		&.h-col-right {
			.social-icons-fixed {
				@include breakpoint (large) {
					right: 0;
				}
			}
		}

	}

		
}
