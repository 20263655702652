.grid-masonry {
  width:100%!important;
  .grid-sizer, .grid-item {
    @include breakpoint(small){
      width: 100%;
    }
    @include breakpoint(large){
      width: 49%;
    }
  }
  &.grid-masonry-md-1 {
    .grid-sizer, .grid-item {
      @include breakpoint(medium){
        width: 100%;
      }
    }
  }
  &.grid-masonry-md-2 {
    .grid-sizer, .grid-item {
      @include breakpoint(medium){
        width: 49%;
      }
    }
  }
  &.grid-masonry-lg-1 {
    .grid-sizer, .grid-item {
      @include breakpoint(large){
        width: 100%;
      }
    }
  }
  &.grid-masonry-lg-2 {
    .grid-sizer, .grid-item {
      @include breakpoint(large){
        width: 49%;
      }
    }
  }
  &.grid-masonry-lg-3 {
    .grid-sizer, .grid-item {
      @include breakpoint(large){
        width: 32%;
      }
    }
  }


  .grid-item {
    background-color: $black;    
    box-shadow: 0 rem-calc(2) rem-calc(10) rgba(0, 0, 0, 0.2);
    height: auto;
    overflow: hidden;
    
    @include breakpoint(small){
      margin-bottom: rem-calc(32);
    }
    @include breakpoint(medium){
      margin-bottom: 2%;
    }

    .item-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 0!important;
      &.item-image-bg {
        img {
          opacity: 0;
        }
      }     
    }    
    
    .item-content-top, .item-content-bottom {
      padding: rem-calc(32);
      .masonry-title {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    .item-content-bottom {
      background-color: $dark-grey;
      padding: rem-calc(32);
      position: relative;
      
      p {
        margin: 0;
      }
      .icon{
        @include vertical-center;
        //background-image: url('/assets/images/icon-salary.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: rem-calc(59);
        left: rem-calc(32);      
        width: rem-calc(59);
      }
    }

    .content-padding-small {
      padding: rem-calc(16);
    }
    .content-padding-medium {
      padding: rem-calc(24);
    }
    .content-padding-large {
      padding: rem-calc(32);
    }
    .content-padding-x-large {
      padding: rem-calc(42);
    }

  }

  // masonry border colors
  &.masonry-border-black {
    .grid-item {
      border: rem-calc(2) solid $black;
    }
  }
  &.masonry-border-dark-grey {
    .grid-item {
      border: rem-calc(2) solid $dark-grey;
    } 
  }
  &.masonry-border-light-grey {
    .grid-item {
      border: rem-calc(2) solid $light-grey;
    } 
  }
  &.masonry-border-white {
    .grid-item {
      border: rem-calc(2) solid $white;
    }
  }  

  // masonry bg colors
  &.masonry-bg-black {
    .grid-item {
      background-color: $black;
    }
  }
  &.masonry-bg-dark-grey {
    .grid-item {
      background-color: $dark-grey;
    } 
  }
  &.masonry-bg-light-grey {
    .grid-item {
      background-color: $light-grey;
    } 
  }
  &.masonry-bg-white {
    .grid-item {
      background-color: $white;
    }
  }

  // masonry font colors
  &.masonry-fc-black {
    .grid-item {
      .masonry-title, p {
        color: $black;
      }
    }
  }
  &.masonry-fc-dark-grey {
    .grid-item {
      .masonry-title, p {
        color: $dark-grey;
      }
    }
  }
  &.masonry-fc-light-grey {
    .grid-item {
      .masonry-title, p {
        color: $light-grey;
      }
    }
  }
  &.masonry-fc-white {
    .grid-item {
      .masonry-title, p {
        color: $white;
      }
    }
  }

  // masonry salary background colors
  &.masonry-salary-bg-black {
    .grid-item {
      .item-content-bottom {
        background-color: $black;
      }
    }
  }
  &.masonry-salary-bg-dark-grey {
    .grid-item {
      .item-content-bottom {
        background-color: $dark-grey;
      }
    }
  }
  &.masonry-salary-bg-light-grey {
    .grid-item {
      .item-content-bottom {
        background-color: $light-grey;
      }
    }
  }
  &.masonry-salary-bg-white {
    .grid-item {
      .item-content-bottom {
        background-color: $white;
      }
    }
  }

  // masonry salary font colors
  &.masonry-salary-fc-black {
    .grid-item {
      .item-content-bottom {
        p {
          color: $black;
        }
      }
    }
  }
  &.masonry-salary-fc-white {
    .grid-item {
      .item-content-bottom {
        p {
          color: $white;
        }
      }
    }
  }
  &.masonry-salary-fc-dark-grey {
    .grid-item {
      .item-content-bottom {
        p {
          color: $dark-grey;
        }
      }
    }
  }
  &.masonry-salary-fc-light-grey {
    .grid-item {
      .item-content-bottom {
        p {
          color: $light-grey;
        }
      }
    }
  }

  &.masonry-border {
    .grid-item {
      border-radius: rem-calc(16);
    }
  }
}

.gutter-sizer { 
  width: 2%; 
}