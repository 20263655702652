//icon block
.icon-item {
  margin-bottom: rem-calc(16);
  text-align: center;
  .icon-item-inner {
    a {
      display: inline-block;
    }
  }
  .icon-item-title {
    font-size: rem-calc(16);
    margin-bottom: 0;
  }
  .icon-item-subtitle {
    font-size: rem-calc(14);
    margin-bottom: 0;
  }
}
