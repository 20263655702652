// single role
.single-role {
  &.role-fc-black {
    h2, p {
      color: $black;
    }
  }
  &.role-fc-white {
    h2, p {
      color: $white;
    }
  }
  &.role-fc-dark-grey {
    h2, p {
      color: $dark-grey;
    }
  }
  &.role-fc-light-grey {
    h2, p {
      color: $light-grey;
    }
  }
  &.role-fc-primary {
    h2, p {
      color: $primary-color;
    }
  }
  .single-role-content {    
    @include breakpoint(small){
      margin: rem-calc(20) 0;
    }
    @include breakpoint(medium){
      margin: rem-calc(32) 0;
    }
  }
  .single-role-buttons {
    .btn {
      @include breakpoint(small){
        margin-right: 0;
      }
      @include breakpoint(medium){
        margin-right: rem-calc(16);
      }
    }
  }
  .single-role-closed-message {
    @extend .section-title-small;
    font-weight: bold;
    margin-bottom: 0; 
    margin-top: rem-calc(32);
  }
}