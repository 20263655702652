// multi image
.multi-image {
  &.ele-margin {
    .cell {
      img {
        margin-bottom: rem-calc(16);
      }
    }
  }
}
.multi-image-full {
  img {
    width: 100%;
  }
}