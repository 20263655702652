// background colours
.bc-black {
  background-color: $black;
}
.bc-white {
  background-color: $white;
}
.bc-dark-grey {
  background-color: $dark-grey;
}
.bc-light-grey {
  background-color: $light-grey;
}

// font colours
.fc-black {
  color: $black;
}
.fc-white {
  color: $white;
}
.fc-dark-grey {
  color: $dark-grey;
}
.fc-light-grey {
  color: $light-grey;
}

// block font colour
.block-fc-black {
  color: $black;
  p, li, h1, h2, h3, h4, h5, h6, p a, li a {
    color: $black;
  }
}
.block-fc-white {
  color: $white; 
  p, li, h1, h2, h3, h4, h5, h6, p a, li a {
    color: $white; 
  }
  
}
.block-fc-dark-grey {
  color: $dark-grey;
  p, li, h1, h2, h3, h4, h5, h6, p a, li a {
    color: $dark-grey;
  }
}
.block-fc-light-grey {
  color: $light-grey;
  p, li, h1, h2, h3, h4, h5, h6, p a, li a {
    color: $light-grey;
  }
}