// video modal
.modal-video {
  // video colors
  &.video-fc-black {
    .video-item {
      color: $black;
    }
  }
  &.video-fc-white {
    .video-item {
      color: $white;
    }
  }
  &.video-fc-dark-grey {
    .video-item {
      color: $dark-grey;
    }
  }
  &.video-fc-light-grey {
    .video-item {
      color: $light-grey;
    }
  }
  //overwrite
  &.video-fc-primary {
    .video-item {
      color: $primary-color;
    }
  }

  &.video-center {
    .video-item {
      text-align: center;
    }
  }
  
  &.video-left {
    .video-item {
      text-align: left;
    }
  }
  
  &.video-right {
    .video-item {
      text-align: right;
    }
  }
}
.video-item {
  .video-poster {
    position: relative;
    .video-content {
      position: relative;
    }
    .btn-play {
      @include vertical-center;
      left: 0;
      margin: 0 auto;
      right: 0;
    }
  }
}

// responsive-embed
.responsive-embed {
  margin-bottom: 0;
  padding-bottom: 58%;
  iframe {
    border: 0;
  }
  &.portrait {
    padding-bottom: 177.78%;
  }
}

// html embed
.html-embed {
  max-width: 100%;
  iframe {
    border: 0;
    max-width: 100%;
  }
}
