.divider {
  display: block;
  @for $i from 1 through 20 {
    $num: $i;
    &.divider-h-#{$num} {
      height: rem-calc($num);
    }
  }
  &.divider-border {
    border-radius: rem-calc(10);
  }
}