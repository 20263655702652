// Dual Column content

.image-item, .video-item {
  @include breakpoint(small){
    position: relative;
  }
  @include breakpoint(large){
    position: static;
  }
  .dual-img-background {
    z-index: 1;
    @include breakpoint(small){
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 100%;
      position: absolute;
      top:0;
      width: calc(100% + 1.25rem);
    }
    @include breakpoint(medium){
      width: calc(100% + 1.875rem);
    }
    @include breakpoint(large){
      width: 100%;
    }
    .btn-play {
      @include vertical-center;
      left: 0;
      margin: 0 auto;
      right: 0;
    }
    .btn-transcript {
      bottom: 0;
      margin: 0;
      position: absolute;
    }
  }
  &.dual-bg {
    .dual-img {
      opacity: 0;
      visibility: hidden;
    }
  }
  .dual-img {
    &.ele-margin {
      img {
        margin-bottom: rem-calc(16);
      }
    }
  }
}

.dual-left {
  .image-item, .video-item {
    .dual-img-background {
      @include breakpoint(small){
        right: -0.625rem;
      }
      @include breakpoint(medium){
        right: -0.9375rem;
      }
      @include breakpoint(large){
        right: 50%;
      }
      .btn-transcript {
        right: 0;
      }
    }
  }
  &.large-2 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 83.33333%;
          width: 25vw;
        }
      }
    }
  }
  &.large-3 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 75%;
          width: 33vw;
        }
      }
    }
  }
  &.large-4 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 66.66667%;
          width: 40vw;
        }
      }
    }
  }
  &.large-5 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 58.33333%;
          width: 46vw;
        }
      }
    }
  }
  &.large-6 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 50%;
          width: 50vw;
        }
      }
    }    
  }
  &.large-7 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 41.66667%;
          width: 56vw;
        }
      }
    }
  }
  &.large-8 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 33.33333%;
          width: 66vw;
        }
      }
    }   
  }
  &.large-9 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 25%;
          width: 70vw;
        }
      }
    } 
  }
  &.large-10 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          right: 16.66667%;
          width: 74vw;
        }
      }
    } 
  }
}

.dual-right {
  .image-item, .video-item {
    .dual-img-background {
      @include breakpoint(small){
        left: -0.625rem;
      }
      @include breakpoint(medium){
        left: -0.9375rem;
      }
      @include breakpoint(large){
        left: 50%;
      }
      .btn-transcript {
        left: 0;
      }
    } 
  }
  &.large-2 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 83.33333%;
          width: 25vw;
        }
      }
    }
  }
  &.large-3 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 75%;
          width: 33vw;
        }
      }
    }
  }
  &.large-4 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 66.66667%;
          width: 40vw;
        }
      }
    }
  }
  &.large-5 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 58.33333%;
          width: 46vw;
        }
      }
    }
  }
  &.large-6 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 50%;
          width: 50vw;
        }
      }
    }    
  }
  &.large-7 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 41.66667%;
          width: 56vw;
        }
      }
    }
  }
  &.large-8 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 33.33333%;
          width: 66vw;
        }
      }
    }   
  }
  &.large-9 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 25%;
          width: 70vw;
        }
      }
    } 
  }
  &.large-10 {
    .image-item, .video-item {
      .dual-img-background {
        @include breakpoint(large){
          left: 16.66667%;
          width: 74vw;
        }
      }
    } 
  }
}

.dual-left, .dual-right {
  &.small-order-2 {
    @include breakpoint(small){
      margin-top: rem-calc(24);
    }
    @include breakpoint(large){
      margin-top: 0;
    }
  }
  .dual-inner {
    @include breakpoint(large){
      &.col-1-left-xx-large, &.col-2-left-xx-large {
        padding-left: rem-calc(128);
      }
      &.col-1-left-x-large, &.col-2-left-x-large {
        padding-left: rem-calc(96);
      }
      &.col-1-left-large, &.col-2-left-large {
        padding-left: rem-calc(64);
      }
      &.col-1-left-medium, &.col-2-left-medium {
        padding-left: rem-calc(32);
      }
      &.col-1-left-small, &.col-2-left-small {
        padding-left: rem-calc(16);
      }
      &.col-1-left-x-small, &.col-2-left-x-small {
        padding-left: rem-calc(8);
      }
      &.col-1-right-xx-large, &.col-2-right-xx-large {
        padding-right: rem-calc(128);
      }
      &.col-1-right-x-large, &.col-2-right-x-large {
        padding-right: rem-calc(96);
      }
      &.col-1-right-large, &.col-2-right-large {
        padding-right: rem-calc(64);
      }
      &.col-1-right-medium, &.col-2-right-medium {
        padding-right: rem-calc(32);
      }
      &.col-1-right-small, &.col-2-right-small {
        padding-right: rem-calc(16);
      }
      &.col-1-right-x-small, &.col-2-right-x-small {
        padding-right: rem-calc(8);
      }
    }
    &.dual-inner-bg {
      &.col-1-left-xx-large, &.col-2-left-xx-large {
        padding: rem-calc(128);
      }
      &.col-1-left-x-large, &.col-2-left-x-large {
        padding: rem-calc(96);
      }
      &.col-1-left-large, &.col-2-left-large {
        padding: rem-calc(64);
      }
      &.col-1-left-medium, &.col-2-left-medium {
        padding: rem-calc(32);
      }
      &.col-1-left-small, &.col-2-left-small {
        padding: rem-calc(16);
      }
      &.col-1-left-x-small, &.col-2-left-x-small {
        padding: rem-calc(8);
      }
    }
  }
}
.dual-full {
  .dual-left, .dual-right {
    .dual-inner {
      @include breakpoint(small only){
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
      }
      @include breakpoint(medium only){
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
      }
    }
    &.large-2 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 16.66667%;
          }
        }
      }
    }
    &.large-3 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){            
            width: 25%;
          }
        }
      }
    }
    &.large-4 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){            
            width: 33.33333%;
          }
        }
      }
    }
    &.large-5 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){            
            width: 41.66667%;
          }
        }
      }
    }
    &.large-6 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 50%;            
          }
        }
      }    
    }
    &.large-7 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 58.33333%;
          }
        }
      }
    }
    &.large-8 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 66.66667%;
          }
        }
      }   
    }
    &.large-9 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 75%;
          }
        }
      } 
    }
    &.large-10 {
      .image-item, .video-item {
        .dual-img-background {
          @include breakpoint(large){
            width: 83.33333%;
          }
        }
      } 
    }
  }

}

.dual-btn, .dual-content, .dual-content-img, .dual-heading, .dual-inner-bg, .dual-img, .dual-video-item {
  position: relative;
  z-index: 1;
}

.small-order-1 {
  .dual-img-background {
    @include breakpoint(small only){
      top: rem-calc(-32);
      height: calc(100% + 2rem);
    }
    @include breakpoint(medium only){
      top: rem-calc(-100);
      height: calc(100% + 6.25rem);
    }
  }
}