// background position
.bg-p-top-center {
  background-position: top center;
}
.bg-p-top-left {
  background-position: top left;
}
.bg-p-top-right {
  background-position: top right;
}
.bg-p-center-center {
  background-position: center center;
}
.bg-p-center-left {
  background-position: center left;
}
.bg-p-center-right {
  background-position: center right;
}
.bg-p-bottom-center {
  background-position: bottom center;
}
.bg-p-bottom-left {
  background-position: bottom left;
}
.bg-p-bottom-right {
  background-position: bottom right;
}

// background size
.bg-s-auto {
  background-size: auto;
}
.bg-s-contain {
  background-size: contain;
}
.bg-s-cover {
  background-size: cover;
}

// overlay tint
.overlay-tint {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}