// apply list
.apply-list {
  &.ele-border {
    border-radius: rem-calc(16);
    overflow: hidden;
    position: relative;

    .extra-row {
      border-radius: 0 0 rem-calc(16) rem-calc(16);
    }
    .extra-row-heading {
      border-radius: rem-calc(16) rem-calc(16) 0 0;
    }
  }
  &.ele-border-list {
    .apply-list-row {
      &.apply-list-row-top {
        @include breakpoint(small only) {
          border-radius: rem-calc(16) rem-calc(16) 0 0;
        }
      }
      &:first-of-type {
        .btn {
          border-radius: 0 rem-calc(16) 0 0;
        }
      }
      &:last-of-type {
        border-radius: 0 0 rem-calc(16) rem-calc(16);
        overflow: hidden;
        .btn {
          border-radius: 0 0 rem-calc(16) 0;
        }
      }
      &.ele-single-list {
        .btn {
          border-radius: 0 rem-calc(16) rem-calc(16) 0;
        }
      }
    }

  }
  .apply-list-row {
    background-color: $dark-grey;
    position: relative;
    @include breakpoint(small){
      margin-bottom: rem-calc(16);
    }
    @include breakpoint(medium){
      margin-bottom: rem-calc(2);
    }
    .apply-list-field {
      color: $white;
      font-size: rem-calc(16);
      padding: rem-calc(10) rem-calc(24);
      
      &.apply-list-title {
        a {
          color: $white;
          font-size: rem-calc(12);
          text-decoration: underline;
        }
      }
    }
    .btn-apply {
      min-height: rem-calc(58);
      padding: 0;
      @include breakpoint(small){
        position: relative;
        width: 100%;
      } 
      @include breakpoint(medium){
        position: initial;
        width: rem-calc(146);
      }    
      a {
        background-color: $black;
        border: rem-calc(3) solid $black;
        border-radius: 0;
        color: $white;
        display: inline-block;
        font-weight: 400;	
        height: 100%;
        min-height: rem-calc(58);
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.25s ease;
        width: 100%; 
        @include breakpoint(medium){
          max-width: rem-calc(146);
        }      
        .btn-name {
          @include vertical-center;
          left: 0;
          right: 0;
          text-align: center;
        }
        &.btn-arrow {
          @include breakpoint(medium){
            max-width: rem-calc(160);
          }
          .btn-name {
            left: rem-calc(26);
            right: auto;            
          }
        }
        @include breakpoint(small only){
          align-content: center;
          align-items: center;
          display: flex;
          justify-content: center;
          padding: rem-calc(16);
          &.btn-arrow {
            .btn-name {
              left: auto;
              right: auto;
            }
          }
          .btn-name {
            left: auto;
            padding-right: rem-calc(10);
            position: relative;
            right: auto;
            top: auto;
            transform: none;            
          }
          .arrow {
            left: auto;
            position: relative;
            right: auto;
            top: auto;
            transform: none;
          }
        }   
      }
    }
    
  }
  .extra-row {
    background-color: $black;
    &.extra-row-heading {
      font-weight: bold;
      margin-bottom: rem-calc(2);
    }
    .extra-field {
      color: $white;
      font-size: rem-calc(16);
      padding: rem-calc(24);
      p{
        color: $white;
        font-size: rem-calc(16);
        margin: 0;
      }
      &.extra-field-placeholder {
        width: rem-calc(146);
      }
    }  
    .btn-extra {
      position: relative;
      @include breakpoint(small){
        width: 100%;
      }
      @include breakpoint(medium){
        margin: rem-calc(16) auto rem-calc(24);
        max-width: rem-calc(300);
        padding: 0;
        width: auto;
      } 
      @include breakpoint(large){
        margin: rem-calc(16) rem-calc(24);
      }    
      a {
        align-items: center;
        align-content: center;
        background-color: $black;
        border: rem-calc(3) solid $black;
        display: flex;
        justify-content: center;
        padding: rem-calc(16);
        position: relative;
        transition: all 0.25s ease;
        .btn-name {          
          margin: 0 auto;
          text-align: center;
        }
        .arrow {
          position: relative;
          transform: none;
          left: auto;
          right: auto;
          top: auto;
        }
        &.btn-arrow {
          .btn-name {
            padding-right: rem-calc(10);
            text-align: left;
          }
        }
      }
    }
  }
  &.ele-upper-content {
    .apply-list-row {
      .apply-list-field {
        text-transform: uppercase;        
      }
      .btn-apply {
        text-transform: none;
      }
    }
    .extra-row {
      .extra-field {
        text-transform: uppercase;
      }
      .extra-field-title, .btn-extra {
        text-transform: none;
      }
    }
    .extra-row-heading {
      .extra-field {
        text-transform: none;
      }      
    }
  }
  &.ele-upper-buttons {
    .apply-list-row {
      .btn-apply {
        text-transform: uppercase;
      }
    }
    .extra-row-heading {
      .extra-field {
        text-transform: uppercase;
      }
    }
    .extra-row {
      .extra-field-title, .btn-extra {
        text-transform: uppercase;
      }
    }
  }
  &.ele-first-b {
    .apply-list-row {
      .apply-list-title {
        font-weight: bold;
        a {
          font-weight: 400;
        }
      }
    }

  }
  &.border-col {
    .apply-list-row, .extra-row {
      border: rem-calc(2) solid $black;
    }
  }
  // border colour
  &.border-c-black {
    .apply-list-row, .extra-row {
      border-color: $black;
    }
  }
  &.border-c-dark-grey {
    .apply-list-row, .extra-row {
      border-color: $dark-grey;
    }
  }
  &.border-c-light-grey {
    .apply-list-row, .extra-row {
      border-color: $light-grey;
    }
  }
  &.border-c-white {
    .apply-list-row, .extra-row {
      border-color: $white;
    }
  }
}

// apply list bg colours
.list-bg-black {
  .apply-list-row {
    background-color: $black;
  }
}
.list-bg-dark-grey {
  .apply-list-row {
    background-color: $dark-grey;
  }
}
.list-bg-light-grey {
  .apply-list-row {
    background-color: $light-grey;
  }
}
.list-bg-white {
  .apply-list-row {
    background-color: $white;
  }
}
// apply list font colours
.list-fc-black {
  .apply-list-row {
    .apply-list-field {
      color: $black;
      a {
        color: $black;
      }
    }

  }
}
.list-fc-dark-grey {
  .apply-list-row {
    .apply-list-field {
      color: $dark-grey;
      a {
        color: $dark-grey;
      }
    }
  }
}
.list-fc-light-grey {
  .apply-list-row {
    .apply-list-field {
      color: $light-grey;
      a {
        color: $light-grey;
      }
    }
  }
}
.list-fc-white {
  .apply-list-row {
    .apply-list-field {
      color: $white;
      a {
        color: $white;
      }
    }
  }
}
// apply button bg colours
.apply-bg-black {
  .apply-list-row {
    .btn-apply {
      a{
        background-color: $black;
        border-color: $black;
        &:hover {
          background-color: $white;
          color: $black;
          .arrow {
            background-color: $black;
          }
        }
      }  
    }  
  }
}
.apply-bg-dark-grey {
  .apply-list-row {
    .btn-apply {
      a{
        background-color: $dark-grey;
        border-color: $dark-grey;
        &:hover {
          background-color: $white;
          color: $dark-grey;
          .arrow {
            background-color: $dark-grey;
          }
        }
      }  
    }  
  }
}
.apply-bg-light-grey {
  .apply-list-row {
    .btn-apply {
      a{
        background-color: $light-grey;
        border-color: $light-grey;
        &:hover {
          background-color: $black;
          color: $light-grey;
          .arrow {
            background-color: $light-grey;
          }
        }
      }  
    }  
  }
}
.apply-bg-white {
  .apply-list-row {
    .btn-apply {
      a{
        background-color: $white;
        border-color: $white;
        &:hover {
          background-color: $black;
          color: $white;
          .arrow {
            background-color: $white;
          }
        }
      }  
    }  
  }
}
// apply button font colours
.apply-fc-black {
  .apply-list-row {
    .btn-apply {
      a{
        color: $black;
        .arrow {
          background-color: $black;
        }
      }  
    } 
  }
}
.apply-fc-dark-grey {
  .apply-list-row {
    .btn-apply {
      a{
        color: $dark-grey;
        .arrow {
          background-color: $dark-grey;
        }
      }  
    } 
  }
}
.apply-fc-light-grey {
  .apply-list-row {
    .btn-apply {
      a{
        color: $light-grey;
        .arrow {
          background-color: $light-grey;
        }
      }  
    } 
  }
}
.apply-fc-white {
  .apply-list-row {
    .btn-apply {
      a{
        color: $white;
        .arrow {
          background-color: $white;
        }
      }  
    } 
  }
}

// extra list bg colours
.extra-bg-black {
  .extra-row {
    background-color: $black;
  }
}
.extra-bg-dark-grey {
  .extra-row {
    background-color: $dark-grey;
  }
}
.extra-bg-light-grey {
  .extra-row {
    background-color: $light-grey;
  }
}
.extra-bg-white {
  .extra-row {
    background-color: $white;
  }
}
// extra list font colours
.extra-fc-black {
  .extra-row {
    .extra-field {
      color: $black;
    }
  }
}
.extra-fc-dark-grey {
  .extra-row {
    .extra-field {
      color: $dark-grey;
    }
  }
}
.extra-fc-light-grey {
  .extra-row {
    .extra-field {
      color: $light-grey;
    }
  }
}
.extra-fc-white {
  .extra-row {
    .extra-field {
      color: $white;
    }
  }
}
// extra list background colours
.extra-btn-bg-black {
  .extra-row {
    .btn-extra {
      a {
        background-color: $black;
        border-color: $black;
        &:hover {
          background-color: $white;
          color: $black;
          .arrow {
            background-color: $black;
          }
        } 
      }
    }
  }
}
.extra-btn-bg-dark-grey {
  .extra-row {
    .btn-extra {
      a {
        background-color: $dark-grey; 
        border-color: $dark-grey;  
        &:hover {
          background-color: $white;
          color: $dark-grey;
          .arrow {
            background-color: $dark-grey;
          }
        }       
      }
    }
  }
}
.extra-btn-bg-light-grey {
  .extra-row {
    .btn-extra {
      a {
        background-color: $light-grey;
        border-color: $light-grey;
        &:hover {
          background-color: $black;
          color: $light-grey;
          .arrow {
            background-color: $light-grey;
          }
        }         
      }
    }
  }
}
.extra-btn-bg-white {
  .extra-row {
    .btn-extra {
      a {
        background-color: $white;
        border-color: $white;
        &:hover {
          background-color: $black;
          color: $white;
          .arrow {
            background-color: $white;
          }
        }        
      }
    }
  }
}
// extra button font colours
.extra-btn-fc-black {
  .extra-row {
    .btn-extra {
      a {
        color: $black;
        .arrow {
          background-color: $black;
        }
      }
    }
  }
}
.extra-btn-fc-dark-grey {
  .extra-row {
    .btn-extra {
      a {
        color: $dark-grey;
        .arrow {
          background-color: $dark-grey;
        }
      }
    }
  }
}
.extra-btn-fc-light-grey {
  .extra-row {
    .btn-extra {
      a {
        color: $light-grey;
        .arrow {
          background-color: $light-grey;
        }
      }
    }
  }
}
.extra-btn-fc-white {
  .extra-row {
    .btn-extra {
      a {
        color: $white;
        .arrow {
          background-color: $white;
        }
      }
    }
  }
}