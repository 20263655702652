// teams
.team-list {

  .team-item {
    color: $white;
    background-color: $black;      
    position: relative;
    h2, h3 {
      color: $white;  
      margin-bottom: 0;      
    }
    .team-item-bottom-inner {
      @include breakpoint(small){     
        margin-top: rem-calc(8);
      }
      @include breakpoint(large){     
        margin-top: rem-calc(16);
      }
    }
  }
  &.ele-margin {
    .team-item {
      margin-bottom: rem-calc(32);
    }
  }

  &.team-full {
    .team-item {   
      .team-item-inner {
        .team-img {
          position: relative;
          img {
            opacity: 0;
          }
          .team-img-bg {
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;  
            position: absolute;        
            top: 0;
            width:100%;
          }
        }
      }
      .team-item-bottom {        
        padding: rem-calc(16);
        width:100%;
      }  
    }
  }
  &.team-side {
    .team-item {
      min-height: rem-calc(260);  
      @include breakpoint(medium){ 
        display: flex;
      } 
      .team-item-inner {
        @include breakpoint(medium){ 
          align-self: stretch;
        } 
        .team-img {       
          &.team-side-50 {
            @include breakpoint(small){     
              width:100%;
            }
            @include breakpoint(medium){     
              width:50%;
            }
            .team-img-bg {
              @include breakpoint(small){     
                width:100%;
              }
              @include breakpoint(medium){     
                width:50%;
              }
            }
          }
          &.team-side-25 {
            @include breakpoint(small){     
              width:100%;
            }
            @include breakpoint(medium){     
              width:25%;
            }
            .team-img-bg {
              @include breakpoint(small){     
                width:100%;
              }
              @include breakpoint(medium){     
                width:25%;
              }
            }
          }
          img {            
            @include breakpoint(medium){ 
              opacity: 0;
            }
          }
          .team-img-bg {
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;          
            top: 0;
            @include breakpoint(small){     
              position: relative;
            }
            @include breakpoint(medium){     
              position: absolute;
            }   
          }
        }
      }
      .team-item-bottom {        
        padding: rem-calc(16);
        &.team-side-50 {
          @include breakpoint(small){     
            width:100%;
          }
          @include breakpoint(medium){    
            width:50%;
          }
        }
        &.team-side-75 {
          @include breakpoint(small){     
            width:100%;
          }
          @include breakpoint(medium){     
            width:75%;
          }
        }
        .team-item-bottom-inner {
          p, li, a {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
          }          
        }
      }
  
    }
  }
  &.team-square {
    .team-item {
      padding: rem-calc(16);      
      .team-item-inner {        
        .team-img {
          text-align: center;
          img {
            margin-bottom: rem-calc(20);
            max-width: rem-calc(200);
          }          
        }
        .team-title {
          text-align: center;
        }
      }
      .team-item-bottom-inner {
        background-color: $white;
        padding: rem-calc(16);
        a {
          display: inline-block;
          margin-top: rem-calc(16); 
        }
      }
  
    }
  }
  &.team-round {
    .team-item {
      padding: rem-calc(16);      
      .team-item-inner {        
        .team-img {
          border: rem-calc(4) solid $white;
          border-radius: 50%;
          margin-bottom: rem-calc(20);
          max-height: rem-calc(200);
          max-width: rem-calc(200);
          overflow: hidden;
          text-align: center;
          img {            
            position: relative;            
          }          
        }
        .team-title {
          text-align: center;
        }
      }
      .team-item-bottom-inner {
        background-color: $white;
        padding: rem-calc(16);
        a {
          display: inline-block;
          margin-top: rem-calc(16); 
        }
      }
  
    }
  }

  &.ele-shadow {
    .team-item {
      box-shadow: 0 rem-calc(2) rem-calc(20) rgba(0,0,0,0.2);
    }
  }
  &.ele-border {
    .team-item {
      border-radius: rem-calc(8);
      overflow: hidden;      

    }
    &.team-square, &.team-round {
      .team-item {
        .team-item-bottom {
          .team-item-bottom-inner {
            border-radius: rem-calc(8);
          }
        }
      }

    }
  }

  // bg colours
  &.ele-bg-black {
    .team-item {
      background-color: $black;
    }
  }
  &.ele-bg-dark-grey {
    .team-item {
      background-color: $dark-grey;
    }
  }
  &.ele-bg-light-grey {
    .team-item {
      background-color: $light-grey;
    }
  }
  &.ele-bg-white {
    .team-item {
      background-color: $white;
    }
  }

  // font colors
  &.ele-fc-black {
    .team-item {
      h2, h3 {
        color: $black;
      }
    }
    &.team-full, &.team-side {
      .team-item {
        .team-item-bottom-inner {
          p, li, a {
            color: $black;
          }
        }
      }
    }
  }
  &.ele-fc-dark-grey {
    .team-item {
      h2, h3 {
        color: $dark-grey;
      }
    }
    &.team-full, &.team-side {
      .team-item {
        .team-item-bottom-inner {
          p, li, a {
            color: $dark-grey;
          }
        }
      }
    }
  }
  &.ele-fc-light-grey {
    .team-item {
      h2, h3 {
        color: $light-grey;
      }
    }
    &.team-full, &.team-side {
      .team-item {
        .team-item-bottom-inner {
          p, li, a {
            color: $light-grey;
          }
        }
      }
    }
  }
  &.ele-fc-white {
    .team-item {
      h2, h3 {
        color: $white;
      }
    }
    &.team-full, &.team-side {
      .team-item {
        .team-item-bottom-inner {
          p, li, a {
            color: $white;
          }
        }
      }
    }
  }

}