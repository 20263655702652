.login-bg {
  background-color: #9B00F9;
  height: 100vh;
  overflow: hidden;
  position: relative;  
  width: 100%;
  .login-container {
    @include vertical-center;
    background-color: $white;
    box-shadow: 0 rem-calc(2) rem-calc(48) rgba(0, 0, 0, 0.2);
    color: $black;
    left: 0;
    margin: 0 auto;
    max-width: rem-calc(320);
    padding: rem-calc(32);
    right: 0;
    img {
      margin-bottom: rem-calc(32);
    }
    label {
      display: block;
      margin-bottom: rem-calc(8);
    }
    #Password {
      padding: rem-calc(10);
    }
  }
}